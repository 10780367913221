module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Things I Got Wrong","short_name":"TIGW Trivia","start_url":"/","background_color":"#4cd4eb","theme_color":"#4cd4eb","display":"minimal-ui","icon":"src/images/tigw_favicon64.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b4bc9a74c2c30f7c0d835385b3a3c5b7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
